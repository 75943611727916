import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Set the workerSrc for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewerComponent = ({ url }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageWidth, setPageWidth] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const updatePageWidth = () => {
            if (containerRef.current) {
                setPageWidth(containerRef.current.offsetWidth);
            }
        };

        // Set initial page width
        updatePageWidth();

        // Add event listener for window resize
        window.addEventListener('resize', updatePageWidth);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', updatePageWidth);
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div ref={containerRef} style={{ width: '100%', overflowX: 'hidden' }}>
            <Document
                file={{ url }}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <div key={`page_${index + 1}`} style={{ margin: '10px 0', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                        <Page
                            pageNumber={index + 1}
                            width={pageWidth - 40} // Adjust for padding and border
                        />
                    </div>
                ))}
            </Document>
        </div>
    );
};

export default PDFViewerComponent;