import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { getDivisions } from '../../../services/userService';
import { EditDivisionModal } from '../../division/EditDivisionModal';
import AddDivision from '../../statistics/statModal/addDivision';

export default function DivisionInput({
                                          selected,
                                          hasValidation,
                                          name,
                                          required,
                                          disabled,
                                          handleInputChange,
                                          canEdit,
                                          canAdd = true,
                                          label,
                                          updateDivisionsFromProps,
                                          division,
                                      }) {
    const [divisions, setDivisions] = useState(division);
    const [loading, setLoading] = useState(false);
    const [notLoaded, setNotLoaded] = useState(true);
    const [selectedDivision, setSelectedDivision] = useState(selected);

    const fetchDivisions = async () => {
        if (notLoaded && !division) {
            setLoading(true);
            const data = await getDivisions();
            if (data) {
                setNotLoaded(false);
                setDivisions(data);
                setLoading(false);
            }
        } else {
            setNotLoaded(false);
            setDivisions(division);
            setLoading(false);
        }
    };

    const updateEditedDivision = (data) => {
        const newDivisions = divisions?.map((division) => (division?.id == data?.id ? data : division));
        setDivisions(newDivisions);
    };

    const updateDivisions = (data) => {
        setDivisions([data, ...divisions]);
        updateDivisionsFromProps(data)
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        const data = {target:{value:parseInt(value), name:name}}
        if (typeof handleInputChange === 'function') {
            handleInputChange(data);
        }
    };

    useEffect(() => {
        setSelectedDivision(selected);
    }, [selected]);

    useEffect(() => {
        fetchDivisions();
    }, []);

    useEffect(() => {
        console.log({divisions,division},division||[])
    }, [selectedDivision]);


    return (
        <Form.Group controlId="validateDivision">
            <Form.Label>{label || 'Division/Dept'}</Form.Label>
            <InputGroup hasValidation={!!hasValidation}>
                <div className="d-flex align-items-center w-100">
                    <Form.Control
                        as="select"
                        required={!!required}
                        disabled={loading || disabled}
                        name={name || 'division'}
                        onChange={handleChange}
                    >
                        {loading ? (
                            <option>Loading...</option>
                        ) : (
                            <>
                                <option value="">-------------</option>
                                {divisions?.map((division) => {
                                    return (
                                        <option key={division?.id} value={division?.id} defaultValue={parseInt(selectedDivision) === division?.id}>
                                            {division?.division_name}
                                        </option>
                                    );
                                })}
                            </>
                        )}
                    </Form.Control>

                    {canAdd && (
                        <AddDivision
                            updateDivisions={updateDivisions}
                            selectDivision={(id) => {
                                setSelectedDivision(id);
                                handleChange({ target: { value: id, name: 'division' } });
                            }}
                        />
                    )}
                    {canEdit && selectedDivision && (
                        <EditDivisionModal
                            btnText={<i className="feather icon-edit mx-0"/>}
                            division={divisions?.find((division) => division.id == selectedDivision)}
                            callBackFunc={updateEditedDivision}
                        />
                    )}
                </div>
                {hasValidation && (
                    <>
                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Please choose a division.</Form.Control.Feedback>
                    </>
                )}
            </InputGroup>
        </Form.Group>
    );
}
