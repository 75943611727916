import React, {useEffect, useCallback, useState, useRef} from 'react';
import { useReactFlow, getBezierPath, getEdgeCenter, getMarkerEnd, Handle } from 'react-flow-renderer';

import {Form} from "react-bootstrap";



const CustomNodeComponent = ({ data, id }) => {
    const [text, setText] = useState(data.label || '');
    const textareaRef = useRef(null);

    useEffect(() => {
        setText(data.label || '');
        adjustHeight();
    }, [data.label]);


    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
// Reset height to 0
            textarea.style.height = '0px';
// Set the height to scrollHeight
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [text]);

    const handleChange = (e) => {
        const value = e.target.value;
        setText(value);
        updateNodeInLocalStorage(id, value);
    };

    const updateNodeInLocalStorage = (nodeId, newLabel) => {
        const nodes = JSON.parse(localStorage.getItem('articleNodes')) || [];
        const updatedNodes = nodes.map((node) => {
            if (node.id === nodeId) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        label: newLabel,
                    },
                };
            }
            return node;
        });
        localStorage.setItem('articleNodes', JSON.stringify(updatedNodes));
    };

    return (
        <div style={{ padding: 10, backgroundColor: '#ddd', borderRadius: '5px' }}>
            <Handle type="target" position="top" />
            <Form.Control
                ref={textareaRef}
                className="text-center text-blackish overflow textarea-auto-resize"
                as="textarea"
                onChange={handleChange}
                placeholder="type any text here"
                value={text}
                style={{
                    minHeight: '25px',
                    resize: 'none',
                    overflow: 'hidden',
                    lineHeight: '1.2', // Adjust this value as needed
                    padding: '2px 0', // Adjust vertical padding as needed
                }}
                plaintext
            />
            <Handle type="source" position="bottom" />
        </div>
    );
};

const DecisionNodeComponent = ({ data, id }) => {
    const [text, setText] = useState(data.label || '');
    const textareaRef = useRef(null);

    useEffect(() => {
        setText(data.label || '');
        adjustHeight();
    }, [data.label]);


    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
// Reset height to 0
            textarea.style.height = '0px';
// Set the height to scrollHeight
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [text]);

    const handleChange = (e) => {
        const value = e.target.value;
        setText(value);
        updateNodeInLocalStorage(id, value);
    };

    const updateNodeInLocalStorage = (nodeId, newLabel) => {
        const nodes = JSON.parse(localStorage.getItem('articleNodes')) || [];
        const updatedNodes = nodes.map((node) => {
            if (node.id === nodeId) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        label: newLabel,
                    },
                };
            }
            return node;
        });
        localStorage.setItem('articleNodes', JSON.stringify(updatedNodes));
    };

    return (
        <div>
                <Handle type="target" position="top" />
        <div style={{ padding: 10, backgroundColor: '#dddFFF', clipPath: 'polygon(15px 0, calc(100% - 15px) 0, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 15px), 0 15px)',
        }}>
            <Form.Control
                ref={textareaRef}
                className="text-center text-blackish overflow textarea-auto-resize"
                as="textarea"
                onChange={handleChange}
                placeholder="type any text here"
                value={text}
                style={{
                    minHeight: '25px',
                    resize: 'none',
                    overflow: 'hidden',
                    lineHeight: '1.2', // Adjust this value as needed
                    padding: '2px 0', // Adjust vertical padding as needed
                }}
                plaintext
            />
            </div>
            <Handle type="source" position="bottom" />
            
        </div>
    );
};

const staticCustomNode = ({ data }) => (
    <div style={{ padding: 10, backgroundColor: '#ddd', borderRadius: '5px' }}>
        <Handle type="target" position="top" isConnectable={false} />
        <div 
        style={{wordWrap: 'break-word', width: 145}}
        className="text-center word-wrap text-blackish "
        >{data.label}</div>
        <Handle type="source" position="bottom"  isConnectable={false}/>
    </div>
); 

CustomNodeComponent.defaultProps = {
    deletable: false,
};

const staticDecisionNode = ({ data }) => (
    <div>
                <Handle type="target" position="top" isConnectable={false} />
    <div 
    style={{ padding: 10, backgroundColor: '#dddFFF', 
        clipPath: 'polygon(15px 0, calc(100% - 15px) 0, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 15px), 0 15px)',
    }}
    >
        <div 
            style={{wordWrap: 'break-word', width: 160, paddingX: 0}}
            className="text-center word-wrap text-blackish form-font"
            >{data.label}
        </div>
    </div>
    <Handle type="source" position="bottom" isConnectable={false} />

    </div>
);

const CustomEdge = ({
                        id,
                        sourceX,
                        sourceY,
                        targetX,
                        targetY,
                        sourcePosition,
                        targetPosition,
                        style = {},
                        data,
                        arrowHeadType,
                        markerEndId,
                    }) => {
    const reactFlowInstance = useReactFlow();

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const inputRef = useRef(null);

    const [text, setText] = useState(data?.label || '');

    useEffect(() => {
        setText(data?.label || '');
        adjustHeight();
    }, [data?.label]);


    const adjustHeight = () => {
        const textarea = inputRef.current;
        if (textarea) {
// Reset height to 0
            textarea.style.height = '0px';
// Set the height to scrollHeight
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [text]);

    const handleChange = useCallback((event) => {
        const newLabel = event.target.value;
        setText(newLabel)

// Update the edge in React Flow
        reactFlowInstance.setEdges((eds) =>
            eds.map((ed) => {
                if (ed.id === id) {
                    return {
                        ...ed,
                        data: { ...ed.data, label: newLabel },
                    };
                }
                return ed;
            })
        );

// Update local storage
        const edges = JSON.parse(localStorage.getItem('articleEdge')) || [];
        const updatedEdges = edges.map((edge) => {
            if (edge.id === id) {
                return {
                    ...edge,
                    data: { ...edge.data, label: newLabel },
                };
            }
            return edge;
        });
        localStorage.setItem('articleEdge', JSON.stringify(updatedEdges));

// Adjust textarea height
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;

    }, [id, reactFlowInstance]);

    useEffect(() => {
        adjustInputWidth(inputRef.current);
    }, [text]);

    const adjustInputWidth = (input) => {
        if (!input) return;
        const tempSpan = document.createElement('span');
        document.body.appendChild(tempSpan);
        tempSpan.style.fontSize = getComputedStyle(input).fontSize;
        tempSpan.style.fontFamily = getComputedStyle(input).fontFamily;
        tempSpan.style.visibility = 'hidden';
        tempSpan.textContent = input.value.length>1? input.value : input.placeholder;
// console.log('textContent',input.value.length, input.placeholder)
        input.style.width = `${tempSpan.offsetWidth + 10}px`; // Add some padding
        document.body.removeChild(tempSpan);
    };

    return (
        <>
            <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
            <foreignObject width={150} height={70} x={edgeCenterX - 75} y={edgeCenterY - 35} requiredExtensions="http://www.w3.org/1999/xhtml">
                <body xmlns="http://www.w3.org/1999/xhtml" style={{ backgroundColor: 'transparent', margin: 0, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <div style={{ minWidth: '100px', minHeight: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid transparent', padding: '0px' }}>
                    <Form.Control
                        ref={inputRef}
                        className="text-center overflow bg-card-standard textarea-auto-resize"
                        as="textarea"
                        onChange={handleChange}
                        placeholder="type any text here"
                        value={data?.label || ''}
                        style={{ height: '20px' }}
                        rows={3}
                        plaintext
                    />
                </div>
                </body>
            </foreignObject>
        </>
    );
};

const StaticCustomEdge = ({
                              id,
                              sourceX,
                              sourceY,
                              targetX,
                              targetY,
                              sourcePosition,
                              targetPosition,
                              style = {},
                              data,
                              arrowHeadType,
                              markerEndId,
                          }) => {
    const reactFlowInstance = useReactFlow();

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const inputRef = useRef(null);

    const [text, setText] = useState(data?.label || '');


    const adjustHeight = () => {
        const textarea = inputRef.current;
        if (textarea) {
// Reset height to 0
            textarea.style.height = '0px';
// Set the height to scrollHeight
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [text]);

    const handleChange = useCallback((event) => {
        const newLabel = event.target.value;
        setText(newLabel)

// Update the edge in React Flow
        reactFlowInstance.setEdges((eds) =>
            eds.map((ed) => {
                if (ed.id === id) {
                    return {
                        ...ed,
                        data: { ...ed.data, label: newLabel },
                    };
                }
                return ed;
            })
        );

// Update local storage
        const edges = JSON.parse(localStorage.getItem('articleEdge')) || [];
        const updatedEdges = edges.map((edge) => {
            if (edge.id === id) {
                return {
                    ...edge,
                    data: { ...edge.data, label: newLabel },
                };
            }
            return edge;
        });
        localStorage.setItem('articleEdge', JSON.stringify(updatedEdges));

// Adjust textarea height
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;

    }, [id, reactFlowInstance]);

    useEffect(() => {
        adjustInputWidth(inputRef.current);
    }, [text]);

    const adjustInputWidth = (input) => {
        if (!input) return;
        const tempSpan = document.createElement('span');
        document.body.appendChild(tempSpan);
        tempSpan.style.fontSize = getComputedStyle(input).fontSize;
        tempSpan.style.fontFamily = getComputedStyle(input).fontFamily;
        tempSpan.style.visibility = 'hidden';
        tempSpan.textContent = input.value.length>1? input.value : input.placeholder;
// console.log('textContent',input.value.length, input.placeholder)
        input.style.width = `${tempSpan.offsetWidth + 10}px`; // Add some padding
        document.body.removeChild(tempSpan);
    };

    return (
        <>
            <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
            <foreignObject width={150} height={70} x={edgeCenterX - 75} y={edgeCenterY - 35} requiredExtensions="http://www.w3.org/1999/xhtml">
                <body xmlns="http://www.w3.org/1999/xhtml" style={{ backgroundColor: 'transparent', margin: 0, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                <div style={{ minWidth: '100px', minHeight: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid transparent', padding: '0px' }}>
<span

    className="text-center form-control-plaintext overflow bg-card-standard "

>{data?.label}</span>
                </div>
                </body>
            </foreignObject>
        </>
    );
};

export const staticEdgeTypes = {
    customEdge: StaticCustomEdge,
};

export const edgeTypes = {
    customEdge: CustomEdge,
};

const nodeTypes = {
    customNode: CustomNodeComponent,
    decisionNode: DecisionNodeComponent,
};

export const staticNodeTypes = {
    customNode: staticCustomNode,
    decisionNode: staticDecisionNode,
};


export default nodeTypes;


