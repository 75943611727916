import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Knowledge } from '../../services';
import CustomTooltip from "../CustomTooltip";

function renderCategoryOptions(category, prefix = '') {
    return (
        <>
            <option key={category.id} id={category.id} value={category.id}>
                {prefix}{category.category}
            </option>
            {category.subcategories?.map((subCategory) =>
                renderCategoryOptions(subCategory, `${prefix}${category.category} > `)
            )}
        </>
    );
}

export function ArticleCategorySelect({ article, setArticle,errors }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const state = useSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const [categoryNameStatus, setCategoryNameStatus] = useState('');
    const [category, setCategory] = useState({ company: state.account.user.company.id, category: '' });
    const [categories, setCategories] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const dispatch = useDispatch();
    const [selectedCat, setSelectedCat] = useState(false);

    console.log({selectedCat})

    const handleChange = (e, f) => {
        // function to set the new category name on user input
        const id = parseInt(e.target.selectedOptions[0].id);
        const value = e.target.value;
        console.log({ e }, f, value);
        setArticle((article) => ({ ...article, article_category: id }));
        setSelectedCat(value);
        // set this so that the new catagory name is set property
    };

    const handleChangeCat = (e) => {
        // function to set the new category name on user input
        const value = e.target.value;
        const name = e.target.name;
        setCategory((cat) => ({ ...cat, [name]: value }));
    };

    const getArticleCategories = async () => {
        setLoading(true);
        const data = await Knowledge.getArticleCategories({ paginate: false });
        data?.sort((a, b) => a.category.localeCompare(b.category));
        setCategories(data);
        if ((article.article_category === null || article.article_category === false) && data.length > 0) {
            setArticle((article) => ({ ...article, article_category: data[0]?.id }));
        }
        setLoading(false);
    };

    useEffect(() => {
        getArticleCategories();
        // article.article_category && setArticle(article => ({...article, article_category: categories[0]}))
    }, [dispatch]);

    useEffect(() => {
        // set the article category as the default selected one on component initialization
        article?.article_category && setSelectedCat(article?.article_category);
    }, [article]);

    const handleUpdateCategory = async (e) => {
        e.preventDefault();
        const res = await Knowledge.patchArticleCategories(selectedCat, {category:category.category, parent: category.parent});

        // Clear the inputs and close the modal
        getArticleCategories();
        setArticle((article) => ({ ...article, article_category: res.id }));
        setSelectedCat(res.category);
        setShowEdit(false);
    };


    const handleCreateCategory = async (e) => {
        e.preventDefault();
        const data = category;
        const res = await Knowledge.postArticleCategories(data);
        // we update the state with the new updated value
        console.log({ res });
        const updatedCategory = res.category;
        getArticleCategories();
        setArticle((article) => ({ ...article, article_category: res.id }));
        setSelectedCat(res.category);
        setShowEdit(false);
    };

    const handleDeleteCategory = async (e) => {
        e.preventDefault();
        // Call the API to delete the selected category
        await Knowledge.deleteArticleCategories(selectedCat);
        // Refresh the categories
        getArticleCategories();
        setArticle((article) => ({ ...article, article_category: categories[0].id }));
        setSelectedCat(categories[0].category);
        setShowEdit(false);
        setIsEditMode(false);
    };

    return (
        <div>
            <Form.Group controlId="category">
                <Form.Label className="mr-3">Article Category</Form.Label>
                <div className="d-flex">
                                                    <Form.Group controlId="articleCategory" className="w-100">

                    <Form.Control as="select" isInvalid={!!errors?.article_category&&!selectedCat} name="article_category" value={selectedCat} onChange={handleChange}>
                        {loading ? (
                            <option disabled={true} value={selectedCat}>
                                Loading...
                            </option>
                        ) : categories.length > 0 ? (
                            categories.map((category) => renderCategoryOptions(category))
                        ) : (
                            <option id={'PLACEHOLDER'} disabled>
                                Please Add Your First Category
                            </option>
                        )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please create at least one category
                    </Form.Control.Feedback>
                                                    </Form.Group>
                    <CustomTooltip title="Create an Article Category">
                        <Button className="btn btn-sm btn-icon btn-primary ml-2 mr-1" onClick={() => {
                            setCategory((cat) => ({ ...cat, category: '' }));
                            setShowEdit(true);
                            setIsEditMode(false);

                        }}>
                            <i className="feather icon-plus" />
                        </Button>
                    </CustomTooltip>
                    <CustomTooltip title="Edit Article Category">
                        <Button className="btn btn-sm btn-icon btn-primary mx-0" onClick={() => {
                            const selectedCategory = categories.find(cat => cat.id === selectedCat);
                            setCategory(selectedCategory);
                            setShowEdit(true);
                            setIsEditMode(true);
                        }}>
                            <i className="feather icon-edit" />
                        </Button>
                    </CustomTooltip>
                </div>
            </Form.Group>

            <Modal show={showEdit} onHide={() => setShowEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Edit Category' : 'Add Category'}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={isEditMode ? handleUpdateCategory : handleCreateCategory}>
                    <Modal.Body>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                name="category"
                                type="text"
                                value={category?.category}
                                onChange={handleChangeCat}
                                placeholder="Enter new category name"
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Parent Category</Form.Label>
                            <Form.Control as="select" name="parent" value={category.parent} onChange={handleChangeCat}>
                        {loading ? (
                            <option disabled={true} value={category.parent}>
                                Loading...
                            </option>
                        ) : categories.length > 0 ? (
                            <>
                                <option key="none" id="none" value="">
                                    NONE
                                </option>
                                {categories
                                    .filter((category) => category.id !== selectedCat)
                                    .map((category) => renderCategoryOptions(category))
                                }
                            </>
                        ) : (
                            <option id="PLACEHOLDER" disabled>
                                Please Add Your First Category
                            </option>
                        )}
                    </Form.Control>
                        </Form.Group>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        {isEditMode &&
                            <Button className='btn-danger mr-auto' onClick={handleDeleteCategory}>
                                Delete
                            </Button>
                        }
                        <Button variant="secondary" onClick={() => setShowEdit(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={categoryNameStatus === 'submitting'}>
                            {categoryNameStatus === 'submitting' ? 'Saving...' : 'Save'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    );
}
