import http from './httpService';
import { API_SERVER } from '../config/constant';
import { toast } from 'react-toastify';
import moment from "moment";

const buildFilterQuery = (filter) => {
    console.log({filter})
    let filterQuery = '';
    if (!(filter.some(item => item.id.toLowerCase() === 'periods'))) {
        filterQuery += `periods=12&`;
        // console.log( "no perionds",filterQuery )
    }
    filter.forEach((f) => {
        console.log("buildFilterQuery",{ f });
        if (f.id === 'Interval') {
            filterQuery += `period_type=${f.name.toLowerCase()}&`;
        } else if (f.id === 'Sort') {
            filterQuery += `${f.id.toLowerCase()}=${
                f.name.toLowerCase() === 'division'
                    ? 'stat_division'
                    : f.name.toLowerCase() === 'employee name'
                        ? 'stat_owner'
                        : f.name.toLowerCase() === 'stat name'
                            ? 'stat_name'
                            : f.name.toLowerCase()
            }&`;
        } else if (f.id.toLowerCase() === 'search') {
            //    this code allows fo special characters in the query strong without them interfering with the code, eg = & etc
            let filteredName = encodeURIComponent(f.name); // encode '&' symbol
            filterQuery += `${f.id.toLowerCase()}=${filteredName.toLowerCase()}&`;
            console.log("buildFilterQueryd",filterQuery,"filteredName:",filteredName,"f.name:",f.name);

            // Periods
        } else if (f.id.toLowerCase() === 'periods') {
            console.log('periods filter 5', f.name.toLowerCase());
            if (f.name.toLowerCase()==="range slider") {
                console.log('periods filter 1')

                filterQuery += `stat_value_period_end=${moment().format(
                    'YYYY-MM-DD'
                )}&stat_value_period_start=${moment()
                    .subtract(f.name.toLowerCase(), 'w')
                    .format('YYYY-MM-DD')}&`;}
            else if (f.name.toLowerCase()==='select dates') {
                console.log('periods filter select dates')
            }
            else {
                console.log('periods filter 3')
                filterQuery += `${f.id.toLowerCase()}=${f.name.toLowerCase()}&`;
            }
        } else {
            if (['My Division/Dept', 'My Stats', 'All Stats'].includes(f.name)) {
                filterQuery += `${f.id.toLowerCase()}=${
                    f.name.toLowerCase() === 'my stats'
                        ? 'my_stats'
                        : f.name.toLowerCase() === 'all stats'
                            ? ''
                            : f.name.toLowerCase() === 'my division'
                                ? 'my_division'
                                : ''
                }&`;
            } else if (f.id.toLowerCase() === 'division') {
                filterQuery += `${f.id.toLowerCase()}=${f.name.toLowerCase()}&`;
            } else if (f.id.toLowerCase() === 'date') {
                filterQuery += `period_interval=${f.name.toLowerCase()}&`;
            } else if (f.id.toLowerCase() === 'tags') {
                filterQuery += `tag=${
                    f.name.toLowerCase() === 'all tags' ? '' : f.name.toLowerCase()
                }&`;
            } else {
                filterQuery += `tag=${
                    f.name.toLowerCase() === 'all tags' ? '' : f.name.toLowerCase()
                }&`;
            }
        }
    });
    return filterQuery;
};

const getStatsFilter = async (filter, source) => {
    try {

        console.log({ filter });
        let filterQuery = buildFilterQuery(filter);
        const { data } = await http.get(`${API_SERVER}statistics/api/list-create/?periods=2&${filterQuery}`, {
            // ${filterQuery}
            ...http.tokenConfig(),
            cancelToken: source?.token
        });

        if (data.includes('<h3 className="mb-4">Error 404</h3>')) {
            throw new Error('404 Page Not Found');
        }
        if (data.includes('<h3 className="mb-4">Error 500</h3>')) {
            throw new Error('Error 500');
        }

        return data;
    } catch (error) {
        console.log(error);
        return;
    }
};

const getStatsFilter2 = async (filter, source) => {
    try {
        console.log({ filter },"getStatsFilter2");

        let filterQuery = buildFilterQuery(filter);
        console.log({ filterQuery },"getStatsFilter2");
        const { data } = await http.get(`${API_SERVER}statistics/api/list-create2/?${filterQuery}`, {
            ...http.tokenConfig(),
            cancelToken: source?.token
        });
        // console.log("statLi",data)
        return data;
    } catch (error) {
        console.log({error});
        return;
    }
};

const updateStats = async (id, stat) => {
    try {
        const { data } = await http.put(`${API_SERVER}statistics/api/update-delete/${id}/`, stat, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error,error.response);
        //todo clean up the error message to have the same info but be in better formatting
        toast.error("Error: " +error.response.request.responseText, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return false;
    }
};

const getStatSettings = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}statistics/api/settings/${id}/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return;
    }
};

const getStatFilter = async (id, query,period, source) => {
    const type = query === 'Weekly'? "WEEKLY": query === 'Monthly'? "MONTHLY": query === 'Daily'? "DAILY": query === 'Quarterly'? "QUARTERLY": query === 'Yearly'? "YEARLY": "DEFAULT"
    const periods =
        period?.endDate?
            `&stat_value_period_start=${moment(period.startDate).format('YYYY-MM-DD')}&stat_value_period_end=${moment(period.endDate).format('YYYY-MM-DD')}`
            :
            period > 0 ? `&periods=${period === undefined ? 12 : period}` : ''

    // this will show you what's being sent to single stat
    console.log({id, query,period,})
    try {
        const { data } = await http.get(`${API_SERVER}statistics/api/single-stat/${id}/?period_type=${type}${periods}`, {
            ...http.tokenConfig(),
            cancelToken: source?.token
        });
        return data;
    } catch (error) {
        console.log('GetStatFilter - Error', error);
        toast.error(`Stat has error, contact support`+ JSON.stringify(error.response?.data), {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
        return;
    }
};



export const getStatsFilterPaginated = async (filter, source, page) => {
    console.log({page})
    try {
        console.log({ filter });
        let filterQuery = buildFilterQuery(filter);

        const { data } = await http.get(`${API_SERVER}statistics/api/list-create-paginated/?${filterQuery}${page ? `page=${page}` : ''}`, {
            ...http.tokenConfig(),
            cancelToken: source?.token
        });
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};


export const getStatsFilterPageNext = async (filter, source, nextPageUrl,period) => {
    console.log({filter, period, source, nextPageUrl})
    try {
        const periods =
            period?.endDate
                ? `&stat_value_period_start=${moment(period.startDate).format('YYYY-MM-DD')}&stat_value_period_end=${moment(period.endDate).format('YYYY-MM-DD')}`
                : period > 0 ? `&periods=${period === undefined ? 12 : period}` : '';

        let filterQuery = buildFilterQuery(filter);
        console.log({ filter, filterQuery });

        let page;
        if (Number.isInteger(Number(nextPageUrl))) {
            page = nextPageUrl;
        } else {
            page = new URLSearchParams(nextPageUrl.split('?')[1]).get('page');
        }

        const { data } = await http.get(
            `${API_SERVER}statistics/api/list-create-all-paginated/?${filterQuery}${periods}${page ? `&page=${page}` : ''}`,
            {
                ...http.tokenConfig(),
                cancelToken: source?.token
            }
        );
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const getStatsFilterPageNext1 = async (filter, source, nextPageUrl) => {
    try {
        
        let filterQuery = buildFilterQuery(filter);
        console.log({ filter, filterQuery });

        // Extract the page number from the nextPageUrl
        // const urlParams = new URLSearchParams(nextPageUrl.split('?')[1]);
        const page = nextPageUrl

        const { data } = await http.get(`${API_SERVER}statistics/api/list-create-all-paginated/?${filterQuery}&page=${page}`, {
            ...http.tokenConfig(),
            cancelToken: source?.token
        });
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const getArchivedStats = async (filter, source) => {
    try {
        console.log({ filter });
        let filterQuery = buildFilterQuery(filter);
        const { data } = await http.get(`${API_SERVER}statistics/api/list-create2/?archived=true&${filterQuery}`, {
            ...http.tokenConfig(),
            cancelToken: source?.token
        });
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

const createStat = async (stat) => {
    try {
        const { data } = await http.post(`${API_SERVER}statistics/api/list-create/`, stat, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error,error.response);
        toast.error("Couldn't Create Stat"+ " " +error.response.request.response, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
};
const deleteStat = async (id) => {
    try {
        const { data } = await http.delete(`${API_SERVER}statistics/api/update-delete/${id}/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error,error.response);
        toast.error("Error: " +error.response.request.response, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
};
const addStatValue = async (stat) => {
    // console.log("rows",stat)
    try {
        const { data } = await http.post(`${API_SERVER}statistics/api/statvalue/create/`, stat, http.tokenConfig());
        console.log('create trust', data);
        toast.success("Stat Value Added "+stat.stat_value+" for "+moment(stat.stat_date).format("l"), {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return data;
    } catch (error) {
        console.log(error,error.response);
        toast.error("Error: " +error.response.request.response, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return { error: error.response.data.non_field_errors };
    }
};

const getOptions = async () => {
    try {
        const { data } = await http.options(`${API_SERVER}statistics/api/list-create/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
};

const getUsers = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/staffs/company_users/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return;
    }
};

const addDivision = async (companyId, division) => {
    try {
        const { data } = await http.post(`${API_SERVER}division/api/`, division, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error,error.response);
        toast.error("Error: " +error.response.request.response, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }
};

const updateValue = async (id, statData) => {
    try {
        const { data } = await http.put(`${API_SERVER}statistics/api/statvalue/update-delete/${id}/`, statData, http.tokenConfig());
        console.log('update value', data);
        toast.success("Stat Value Updated Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return data;
    } catch (error) {
        console.log(error);
        toast.error("Stat Update Failed" + " " + error.response.request.responseText, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return false;
    }
};


const deleteValue = async (id) => {
    try {
        const { data } = await http.delete(`${API_SERVER}statistics/api/statvalue/update-delete/${id}/`, http.tokenConfig());
        console.log('create trust', data);
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getDivision = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}division/api/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return;
    }
};

const getPositions = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/positions/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return;
    }
};

export default {
    addStatValue,
    getStatsFilter,
    getStatsFilter2,
    getStatsFilterPaginated,
    getStatsFilterPageNext,
    deleteStat,
    updateStats,
    createStat,
    getOptions,
    getUsers,
    addDivision,
    updateValue,
    deleteValue,
    getDivision,
    getPositions,
    getStatFilter,
    getStatSettings,
};
