import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import {ConfigContext} from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import {useSelector} from "../../../../store";

const NavLogo = () => {
    const configContext = useContext(ConfigContext);
    const {collapseMenu} = configContext.state;
    const {dispatch} = configContext;
    const user = useSelector((state) => state.account.user);


    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    console.log("user.company?.company_logo", process.env.REACT_APP_TRAINING, process.env.REACT_APP_TRAINING_NAME)

    return (
        <React.Fragment>
            <div className={"navbar-brand header-logo  " + (user.company?.company_logo ? "force-center" : "")}>
                <Link to="/account/dashboard" className="b-brand ">
                    {/*Company name and logo*/}
                    {user.company?.company_logo?
                        <div>
                        <div className="force-center mt-2  force-vertical-center">
                            <div className="">
                            <img className="img-fluid force-center " data-wg-notranslate="manual"
                                 style={{maxWidth:179, maxHeight: 70, objectFit: 'contain', }}
                                 src={user.company?.company_logo} alt="company-logo"/>
                                <span className="b-title ">
                                <span
                                    className="powered-by pt-2">
                                    Powered by {process.env.REACT_APP_NAME}
                                </span>
                            </span>
                            </div>
                            <br/>
                        </div>
                        </div>
                        :
                        <>
                            <div className="b-bg">
                                <i className="feather icon-trending-up"/>
                            </div>
                            <div style={{width:150}} className="force-center">
                            <span className="b-title text-light force-center" >
                                <h5 classoName=" text-light mt-n3 pt-0 force-center" >{process.env.REACT_APP_NAME}</h5>
                                <h6 className="text-light mt-n1 font-weight-light">
                                    <small id="company_name_nav">{user.company?.company_name}</small>
                                </h6>
                            </span>
                                </div>
                        </>}
                </Link>
                <Link
                    to="#"
                    className={toggleClass.join(' ')}
                    id="mobile-collapse"
                    onClick={() => dispatch({type: actionType.COLLAPSE_MENU})}
                >
                    <span/>
                </Link>
            </div>
        </React.Fragment>
    );
};

export default NavLogo;
