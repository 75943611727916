import React from 'react';
import PDFViewer from './PDFViewer';
import AudioPlayer from './AudioPlayer';

const RenderFiles = ({ urls, admin }) => {
    const extension = (file) => file?.split('.').pop().toLowerCase();

    // Ensure urls is always an array
    const urlArray = Array.isArray(urls) ? urls : [urls];

    return (
        <>
            {urlArray.map((url) => {
                const fileExt = extension(url);

                if (fileExt === 'pdf') {
                    return (
                        <div className="container" key={url}>
                            <PDFViewer url={url} />
                        </div>
                    );
                }

                if (['ppt', 'pptx', 'pps', 'ppsx'].includes(fileExt)) {
                    return (
                        <div className="container" key={url}>
                            <iframe
                                id="showpptx"
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                                frameBorder="0"
                                width="100%"
                                height="500px"
                                onContextMenu={(e) => !admin && e.preventDefault()}
                            />
                        </div>
                    );
                }

                if (['mp3', 'wav', 'ogg', 'aac', 'flac', 'm4a'].includes(fileExt)) {
                    return (
                        <div className="container" key={url}>
                            <AudioPlayer url={url} admin={admin} />
                        </div>
                    );
                }

                if (['csv', 'xls', 'xlsx', 'ods','doc', 'docx', 'dot', 'dotx', 'rtf'].includes(fileExt)) {
                    return (
                        <div className="container" key={url}>
                            <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                                frameBorder="0"
                                width="100%"
                                height="1200px"
                                onContextMenu={(e) => !admin && e.preventDefault()}
                            />
                        </div>
                    );
                }

                if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'].includes(fileExt)) {
                    return (
                        <div className="container" key={url}>
                            <img src={url} alt={url.split('/').pop()} style={{ width: '100%', height: 'auto' }} />
                        </div>
                    );
                }

                return (
                    <div className="container" key={url}>
                        <a href={url}>
                            Download: {url.split('/').pop().replace(/[_-]|%20/g, ' ')}
                        </a>
                    </div>
                );
            })}
        </>
    );
};

export default RenderFiles;
