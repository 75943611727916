import React, { useCallback, useContext, useRef } from 'react';

import { useSelector } from 'react-redux';
import { API_SERVER } from '../config/constant';
// import { store } from '../store';
import { Editor } from '@tinymce/tinymce-react';
import mammoth from 'mammoth';
import * as XLSX from 'xlsx';
import { ConfigContext } from '../contexts/ConfigContext';
import httpService from '../services/httpService';

// important! when this editor is inside a modal, ensure that modal has enforceFocus={false} and backdrop="static" set it that modals props

export default function RichTextEditor({
    value,
    onChange,
    disablePaste,
    readOnly,
    simple,
    placeholder,
    height,
    minHeight,
    enableAI = true
}) {
    const company = useSelector((state) => state.account?.user?.company);
    const { layoutType } = useContext(ConfigContext).state;
    const editorRef = useRef(null);

    const handleStopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);

    const ai_request = async (request) => {
        const modifiedPrompt = `
  Answer the question based on the context below.\\n
  You are an ai assistant who's purpose is to help with generating and creating official 
  company policies, hats (a write up of how to do something), job write ups, quizzes, SOP's and other training material.  \\n
  When referring to a company name, use "${company.company_name}". \\n
  Your answer should only be html that would be directly inserted into tinymce. \\n
  Your answer should only contain the text in tags. it should not start with "html" 
  and should end with closing the tag  \n
  Never respond with just the title unless specifically asked for a title \n
  never tell the reader to check with the employee handbook or hr for policies or other information unless specifically asked to \n
  if generating a title or heading always use h1 tags. and utilize the other h tags where applicable
   ${request.system.join('\n')}\n\nContext: "${request.context}"\n\nQuestion:
    "${request.query}"\n\nAnswer:`;

        const openAiOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                model: 'gpt-4o-mini',
                temperature: 0.7,
                max_tokens: 800,
                messages: [{ role: 'user', content: modifiedPrompt }]
            })
        };

        const controller = new AbortController();
        const { signal } = controller;

        return new Promise((resolve, reject) => {
            window
                .fetch(`${API_SERVER}company/api/openai-proxy/`, { signal, ...openAiOptions }, httpService.tokenConfig())
                .then(async (response) => {
                    if (response.ok) {
                        const data = await response.json();
                        if (data.error) {
                            reject(new Error(`${data.error.type}: ${data.error.message}`));
                        } else {
                            const aiResponse = data?.choices[0]?.message?.content?.trim();
                            resolve(aiResponse);
                        }
                    } else {
                        reject(new Error('Failed to communicate with the ChatGPT API'));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const openCustomAIModal = (editor) => {
        let isGenerating = false;

        let previewhtml = '<div style="border: 1px solid #ccc; padding: 10px; min-height: 100px;">Preview will appear here...</div>';
        let buttonText = 'Generate';

        const updateButtonState = (api, state) => {
            api.setEnabled(!state);
            api.setData({ buttonText: state ? 'Generating...' : 'Generate' });
        };

        console.log(' mmeedd editor', editor);

        // editor.setData({
        //     iframe: '<!DOCTYPE html>' +
        //             '<html>' +
        //             '<head></head>' +
        //             '<body><p>Content cvbcvbchere.</p></body>' +
        //             '</html>'
        //   })

        editor.windowManager.open({
            title: 'AI Assistant',
            body: {
                type: 'panel',
                items: [
                    {
                        type: 'textarea',
                        name: 'query',
                        label: 'Ask AI',
                        placeholder: 'Generate a Paid Time Off Policy...'
                    },
                    {
                        type: 'iframe',
                        name: 'iframe',
                        label: 'Preview',
                        html: previewhtml,
                        iframe: previewhtml
                    }
                ]
            },
            buttons: [
                {
                    type: 'submit',
                    text: buttonText,
                    name: 'submitButton',
                    buttonType: 'primary'
                },
                {
                    type: 'custom',
                    text: 'Insert',
                    name: 'InsertButton'
                },
                {
                    type: 'cancel',
                    text: 'Close'
                }
            ],
            onSubmit: (api, e) => {
                const data = api.getData();
                console.log(' mmeedd rpreviewhtml', api, e, data);
                api.setData({
                    iframe:
                        '<!DOCTYPE html>' +
                        '<html>' +
                        '<head>' +
                        `<style>
                                    body {
                                        font-family: 'Segoe UI', sans-serif;
                                    }
                                </style>` +
                        '</head>' +
                        `<body>Generating...
                                </body>` +
                        '</html>'
                });

                updateButtonState(api, true);
                ai_request({ query: data.query, context: value, system: [] })
                    .then((aiResponse) => {
                        api.setData({ preview: aiResponse });
                        buttonText = 'Generating...';
                        api.setData({
                            iframe:
                                '<!DOCTYPE html>' +
                                '<html>' +
                                `<style>
                                    body {
                                        font-family: 'Segoe UI', sans-serif;
                                    }
                                </style>` +
                                '<head></head>' +
                                `<body>${aiResponse}</body>` +
                                '</html>'
                        });
                        api.setData({ submitButton: 'Insert' });
                        updateButtonState(api, false);
                        console.log(' mmeedd previewhtml', previewhtml);
                        isGenerating = false;
                    })
                    .catch((error) => {
                        console.error('Error during AI request:', error);
                        api.setData({
                            iframe:
                                '<!DOCTYPE html>' +
                                '<html>' +
                                `<style>
                                    body {
                                        font-family: 'Segoe UI', sans-serif;
                                    }
                                </style>` +
                                `<body>Error during AI request: ${error}</body>` +
                                '</html>'
                        });
                        updateButtonState(api, false);
                        isGenerating = false;
                    });
            },
            onAction: (api, e) => {
                console.log(' mmeedd onAction', api, e);
                const data = api.getData();
                if (editorRef.current) {
                    console.log(' mmeedd inserting content', data, data.iframe);
                    editorRef.current.insertContent(data.iframe);
                }
                api.close();
            }
        });
    };

    const tinyMCEConfig = {
        // readonly: readOnly ? 1 : 0,
        height: height || 500,
        contextmenu: false,
        disabled: readOnly,
        editable_root: !readOnly,
        selector: 'textarea', // change this value according to your HTML
        skin: readOnly ? 'borderless' : 'oxide', // Name of the skin
        // content_css: 'borderless',  // Name of the content skin
        // This is to deal with files,
        // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#interactive-example
        // todo above is the doc. it's commented out for now but needs to be fixed
        // file_picker_types: 'file media',
        // file_picker_callback: (cb, value, meta) => {
        //     const input = document.createElement('input');
        //     input.setAttribute('type', 'file');
        //     input.setAttribute('accept', 'video/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation');
        //
        //     input.addEventListener('change', (e) => {
        //         const file = e.target.files[0];
        //
        //         const reader = new FileReader();
        //         reader.addEventListener('load', () => {
        //             /*
        //               Note: Now we need to register the blob in TinyMCEs image blob
        //               registry. In the next release this part hopefully won't be
        //               necessary, as we are looking to handle it internally.
        //             */
        //             const id = 'blobid' + (new Date()).getTime();
        //             const blobCache =  false
        //             const base64 = reader.result.split(',')[1];
        //             const blobInfo = blobCache.create(id, file, base64);
        //             blobCache.add(blobInfo);
        //
        //             /* call the callback and populate the Title field with the file name */
        //             cb(blobInfo.blobUri(), { title: file.name });
        //         });
        //         reader.readAsDataURL(file);
        //     });
        //
        //     input.click();
        // },
        // block_unsupported_drop: true,
        statusbar: !readOnly,
        setup: function (editor) {
            editor.on('click', function (e) {
                e.stopPropagation();
            });

            // Add custom AI button
            editor.ui.registry.addButton('customAIBtn', {
                text: 'Write With AI',
                onAction: () => openCustomAIModal(editor)
            });
        },
        menubar: !readOnly,
        autoresize_bottom_margin: 0,
        plugins: ` advlist file autoresize autolink lists checklist link image editimage print preview anchor
            searchreplace visualblocks code fullscreen media mediaembed emoticons formatpainter
            insertdatetime media table paste code help powerpaste wordcount`,
        toolbar: readOnly
            ? false
            : `undo redo | formatselect ${enableAI ? 'customAIBtn' : ''} | bold italic backcolor | 
            // alignleft aligncenter alignright alignjustify formatpainter | file image 
            bullist numlist checklist outdent indent | emoticons removeformat | help`,
        placeholder: placeholder || 'Please add your article here...',
        ai_request: enableAI ? ai_request : null,
        branding: false,
        min_height: minHeight || readOnly || 400,
        paste_data_images: true,
        // images_upload_url: `${API_SERVER}knowledgebase/upload_file/tinymce/`,
        // images_upload_handler: function (blobInfo, success, failure) {
        //     // Define how to handle file uploads here
        // },
        content_style:
            layoutType === 'dark'
                ? `
        body { color: #f8f8f8; border: none !important; background: #262729} /* Default text color */
        p { color: #f8f8f8; } /* Paragraph text color */
        h1, h2, h3, h4, h5, h6 { color: #f8f8f8; } /* Heading text color */
        .custom-class { color: #f8f8f8; } /* Custom class text color */
        ${readOnly} ? '.tox-tinymce { border: none !important; }' : ''
    `
                : `${readOnly} ? '.tox-tinymce { border: none !important; }' : ''`
        // setup: function(editor) {
        //     editor.on('change', function(e) {
        //         onChange(editor.getContent());
        //     });
        // }
    };

    const handleFileDrop = (event) => {
        console.log(' mmeedd handleFileDrop', event);
        const file = event.dataTransfer.files[0];
        console.log(' mmeedd file', file);

        if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            event.preventDefault();

            console.log(' mmeedd file type is supported');
            const reader = new FileReader();
            reader.onload = function (event) {
                const arrayBuffer = event.target.result;
                console.log(' mmeedd arrayBuffer', arrayBuffer);
                mammoth
                    .convertToHtml({ arrayBuffer: arrayBuffer })
                    .then(function (result) {
                        console.log(' mmeedd result', result);
                        const html = result.value;
                        if (editorRef.current) {
                            console.log(' mmeedd setting content', html);
                            editorRef.current.setContent(html);
                        }
                    })
                    .catch(function (err) {
                        console.error('Error converting document:', err);
                    });
            };
            reader.readAsArrayBuffer(file);
        } else if (
            file &&
            (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel')
        ) {
            event.preventDefault();

            console.log(' mmeedd Excel file type is supported');
            const reader = new FileReader();
            reader.onload = function (event) {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Convert the first sheet to HTML
                const firstSheetName = workbook.SheetNames[0];
                const html = XLSX.utils.sheet_to_html(workbook.Sheets[firstSheetName]);

                if (editorRef.current) {
                    console.log(' mmeedd setting content', html);
                    editorRef.current.setContent(html);
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            alert('Dropped file type is not supported, we only support word and excel files');
        }
    };

    return (
        <div className={readOnly ? 'readOnly text-editor-component' : 'text-editor-component'} onClick={handleStopPropagation}>
            {readOnly ? (
                <div class="mce-content-body" dangerouslySetInnerHTML={{ __html: value }} />
            ) : (
                <Editor
                    onDrop={handleFileDrop}
                    onInit={(evt, editor) => (editorRef.current = editor)} // Capture the editor instance
                    ref={editorRef}
                    value={value} // Use value prop for controlled components
                    apiKey="4x7nzqhqibirfrmbzep9uy2ixg57pxrasnixye30y7risj53"
                    init={tinyMCEConfig}
                    onEditorChange={(content, editor) => !readOnly && onChange(content)}
                />
            )}
        </div>
    );
}
