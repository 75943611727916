import React, { useRef, useState } from 'react';

const AudioPlayer = ({ url, admin }) => {
    const audioRef = useRef(null);
    const [playbackRate, setPlaybackRate] = useState(1);

    const changePlaybackRate = (rate) => {
        if (audioRef.current) {
            audioRef.current.playbackRate = rate;
            setPlaybackRate(rate);
        }
    };

    return (
        <div className="audio-player">
            <div className="audio-container">
                <audio ref={audioRef} controls controlsList={admin ? '' : 'nodownload'} className="audio-element">
                    <source src={url} type={`audio/${url.split('.').pop().toLowerCase()}`} />
                    Your browser does not support the audio element.
                </audio>
                <div className="playback-controls">
                    <button onClick={() => changePlaybackRate(0.5)} className={playbackRate === 0.5 ? 'active' : ''}>0.5x</button>
                    <button onClick={() => changePlaybackRate(1)} className={playbackRate === 1 ? 'active' : ''}>1x</button>
                    <button onClick={() => changePlaybackRate(1.5)} className={playbackRate === 1.5 ? 'active' : ''}>1.5x</button>
                    <button onClick={() => changePlaybackRate(2)} className={playbackRate === 2 ? 'active' : ''}>2x</button>
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;
